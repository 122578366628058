import React from 'react';
import { useParams } from "react-router-dom";

import { TplWSidebar as Tpl } from '#Template'
import Form, { Input } from '#Form'

const ServiceEditNextcloudPage = ({ domainName }) => {
	const { domId:domain, id:_id } = useParams()

	const formDef = {
		callSet: _id ? "services/nextcloud/update" : "services/nextcloud/create",
		callGet: _id && "services/get",
		idRedirect: _id => [ domain, 'services/edit/nextcloud', _id ],
		_id,
		extraData: { domain },
	}

	return (
		<Tpl title={ (_id ? 'Modifica' : 'Crea') + ' servizio cloud'} backTo={'/' + domain + '/services'}>
			<Form {...formDef}>
				<Input label="Indirizzo" name="address" required suffix={'.'+domainName} />
			</Form>
		</Tpl>
	)
}
export default ServiceEditNextcloudPage