import React from 'react';
import { useParams } from "react-router-dom";

import { TplWSidebar as Tpl } from '#Template'
import Form, { Input, InputMulti } from '#Form'

const ServiceEditAppPage = ({ domainName }) => {
	const { domId:domain, id:_id } = useParams()

	const formDef = {
		callSet: _id ? 'services/app/update' : 'services/app/create',
		callGet: _id && 'services/get',
		_id,
		idRedirect: _id => [ domain, 'services/edit/app', _id ],
		extraData: { domain },
	}

	const title = (_id ? 'Modifica' : 'Crea') + ' app'
	return (
		<Tpl title={title} backTo={'/' + domain + '/services'}>
			<Form {...formDef}>
				<Input label="Indirizzo" name="address" suffix={'.'+domainName} />
				<Input name="description" label="Nome app" required />
				<InputMulti name="options.aclsUser" label="Acl utente" />
				<InputMulti name="options.aclsGroup" label="Acl gruppo" />
			</Form>
		</Tpl>
	)
}
export default ServiceEditAppPage
