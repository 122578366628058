import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { faUsers } from '@fortawesome/pro-regular-svg-icons'

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'

import { Block, Checkbox, EntityAclsV2 } from '../genericAcl'

const Group = ({ user, group }) => {
	const api = useContext(APICtx)
	const [ isMember, setIsMember ] = useState(null)

	const handleChange = () => {
		setIsMember(null)
		api.call(isMember ? 'acl/membership/unset' : 'acl/membership/set', { user, group })
			.then(handleRefresh)
	}
	const handleRefresh = () => api.call('acl/membership/get', { user, group }).then(setIsMember)
	useEffect(() => {
		handleRefresh()
	}, [ group ])

	return (
		<Checkbox
			label={group}
			handleChange={handleChange}
			active={isMember}
		/>
	)
}

const Groups = ({ domain, user }) => {
	const api = useContext(APICtx)
	const [ groups, setGroups ] = useState([])

	useEffect(() => {
		api.call('groups/list', { domId:domain }).then(setGroups)
	}, [ domain ])

	return (
		<Block label="Gruppi" icon={faUsers}>
			{ groups.map(group => (
				<Group key={group} user={user} group={group} domain={domain} />
			))}
		</Block>
	)
}

const UserAclPage = () => {
	const api = useContext(APICtx)
	const { domId, uid:user } = useParams()
	const [ acls, setAcls ] = useState([])

	const handleSet = ({ aclTag, service }) =>
		api.call('acl/user/set', { user, tag:aclTag, service, domain:domId })
	const handleUnset = ({ aclTag, service }) =>
		api.call('acl/user/unset', { user, tag:aclTag, service, domain:domId })

	useEffect(() => {
		api.call('acl/user/list/services', { _id:user }).then(setAcls)
	}, [ domId ])

	return (
		<Tpl title="Permessi utente">
			<EntityAclsV2
				servicesAcls={acls}
				handleSet={handleSet}
				handleUnset={handleUnset}
			>
				<Groups domain={domId} user={user} />
			</EntityAclsV2>
		</Tpl>
	)
}
export default UserAclPage