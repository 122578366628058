import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import { Checkbox, useListFlag } from '../genericAcl'

const AclTableHead = ({ entityName, acl }) => (
	<TableHead>
		<TableRow>
			<TableCell sx={{ fontWeight:'bold' }}>{entityName}</TableCell>
			{ acl && acl.map(aclItem => (
				<TableCell sx={{ fontWeight:'bold' }} key={aclItem}>{aclItem}</TableCell>
			))}
		</TableRow>
	</TableHead>
)

const AclSwitchV2 = ({ address, aclCol, handleSet, handleUnset }) => {
	const [ isSyncing, setSincyng ] = useState(false)
	const [ isGranted, setGranted ] = useState(false)

	useEffect(() => {
		setGranted(Boolean(aclCol.entities.find(item => item.address === address)?.isGranted))
	}, [])

	const handleSwitch = async () => {
		setSincyng(true)
		const op = isGranted ? handleUnset({ address, tag:aclCol.tag }) : handleSet({ address, tag:aclCol.tag })
		return op
			.then(() => {
				setSincyng(false)
				setGranted(!isGranted)
			})
			.catch(error => {
				setSincyng(false)
				throw error
			})
	}
	return (
		<Checkbox
			active={isSyncing ? null : isGranted}
			handleChange={handleSwitch}
		/>
	)
}
const AclTableV2 = ({ title, aclsMap, handleSet, handleUnset }) => {
	return (
		<TableContainer>
			<Table size="small">
				<AclTableHead entityName={title} acl={aclsMap.map(acl => acl.label || acl.tag )} />
				<TableBody>
				{ aclsMap?.[0]?.entities.map(row => (
					<TableRow key={row.address}>
						<TableCell>{row.address}</TableCell>
						{ aclsMap.map(aclCol => (
							<TableCell key={aclCol.tag}>
								<AclSwitchV2 aclCol={aclCol} address={row.address} handleSet={handleSet} handleUnset={handleUnset} />
							</TableCell>
						))}
					</TableRow>
				))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

const ServiceAclPage = () => {
	const api = useContext(APICtx)
	const { svcId, domId:domain } = useParams()
	// const [ service, setService ] = useState({})
	const [ aclUsers, setAclUsers ] = useState([])
	const [ aclGroups, setAclGroups ] = useState([])
	const [ aclDevices, setAclDevices ] = useState([])

	useEffect(() => {
		api.call('acl/service/list/users', { _id:svcId, domain }).then(setAclUsers)
		api.call('acl/service/list/groups', { _id:svcId, domain }).then(setAclGroups)
		api.call('acl/service/list/devices', { _id:svcId, domain }).then(asd => {
			setAclDevices(asd)
			console.log('dAAE', asd)
		})
	}, [ svcId ])

	const handleUserSet = ({ address, tag }) =>
		api.call('acl/user/set', { user:address, tag, service:svcId, domain })
	const handleUserUnset = ({ address, tag }) =>
		api.call('acl/user/unset', { user:address, tag, service:svcId, domain })
	const handleGroupSet = ({ address, tag }) =>
		api.call('acl/group/set', { group:address, tag, service:svcId, domain })
	const handleGroupUnset = ({ address, tag }) =>
		api.call('acl/group/unset', { group:address, tag, service:svcId, domain })
	const handleDeviceSet = ({ address, tag }) =>
		api.call('acl/device/set', { device:address, tag, service:svcId, domain })
	const handleDeviceUnset = ({ address, tag }) =>
		api.call('acl/device/unset', { device:address, tag, service:svcId, domain })

	return (
		<Tpl title="Permessi del servizio">
			{ Boolean(aclUsers?.length) && (
				<Box>
					{/* <Users service={service} /> */}
					<AclTableV2
						title="Utenti"
						aclsMap={aclUsers}
						handleSet={handleUserSet}
						handleUnset={handleUserUnset}
					/>
				</Box>
			)}
			{ Boolean(aclGroups?.length) && (
				<Box sx={{ mt:2 }}>
					{/* <Groups service={service} /> */}
					<AclTableV2
						title="Gruppi"
						aclsMap={aclGroups}
						handleSet={handleGroupSet}
						handleUnset={handleGroupUnset}
					/>
				</Box>
			)}
			{ Boolean(aclDevices?.length) && (
				<Box sx={{ mt:2 }}>
					<AclTableV2
						title="Dispositivi"
						aclsMap={aclDevices}
						handleSet={handleDeviceSet}
						handleUnset={handleDeviceUnset}
					/>
				</Box>
			)}
		</Tpl>
	)
}
export default ServiceAclPage