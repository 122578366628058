import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { faEdit, faKey, faLock, faUnlock, faTrash } from '@fortawesome/pro-regular-svg-icons'
import { faDiagramProject, faCloud, faEnvelope, faGlobe, faWindow } from '@fortawesome/pro-regular-svg-icons'

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Search, { SearchText } from '#Search'
import List, { ListItem } from '#List'
import SpeedDial from '#SpeedDial'

const svcIcons = {
	app: faWindow,
	nextcloud: faCloud,
	mail: faEnvelope,
	hosting: faGlobe,
	openvpn: faDiagramProject,
}

const Service = ({ _id, domain, handleListRefresh }) => {
	const api = useContext(APICtx)
	const [ data, setData ] = useState({})

	const handleDelete = () => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler elimnare il servizio "'+data?.displayName+'"?',
			onConfirm: () => api.call('services/del', { _id, domain }).then(handleListRefresh),
		})
	}

	const handleRefresh = () => api.call('services/get', { _id, domain }).then(setData)
	useEffect(() => {
		handleRefresh()
	}, [ _id, domain ])

	const handleLockToggle = () =>
		api.call(data.isLocked ? 'services/unlock' : 'services/lock', { _id, domain })
			.then(handleRefresh)

	const actions = [
		{ tooltip:'Modifica', icon:faEdit, linkTo:"edit/"+data.type+'/'+_id },
		{ tooltip:'Permessi', icon:faKey, linkTo:"acl/"+_id },
		{ tooltip:'Blocca', icon:faUnlock, onClick:handleLockToggle },
		{ tooltip:'Sblocca', icon:faLock, onClick:handleLockToggle },
		{ tooltip:'Elimina', icon:faTrash, onClick:handleDelete },
	]
	actions.splice(data.isLocked ? 2 : 3, 1)

	const icon = useMemo(() => (
		data.isLocked ? faLock : (svcIcons[data.type] || '??')
	), [ data?.type, data?.isLocked ])

	const fullAddress = (data.address ? data.address+'.' : '') + data.domain
	return (
		<ListItem
			avatar={icon}
			label={fullAddress}
			sublabel={data.description}
			actions={actions}
		/>
	)
}

const ServiceListPage = () => {
	const api = useContext(APICtx)
	const [ services, setServices ] = useState([])
	const [ searchTxt, setSearchTxt ] = useState('')
	const { domId } = useParams()

	const handleRefresh = () => api.call('services/list', { domId, searchTxt }).then(setServices)
	useEffect(() => {
		handleRefresh()
	}, [ domId, searchTxt ])

	const actions = [
		{ tooltip:'Hosting', icon:svcIcons.hosting, linkTo:'/'+domId+'/services/edit/hosting' },
		{ tooltip:'Email', icon:svcIcons.mail, linkTo:'/'+domId+'/services/edit/mail' },
		{ tooltip:'Cloud', icon:svcIcons.nextcloud, linkTo:'/'+domId+'/services/edit/nextcloud' },
		{ tooltip:'App', icon:svcIcons.app, linkTo:'/'+domId+'/services/edit/app' },
		{ tooltip:'OpenVPN', icon:svcIcons.openvpn, linkTo:'/'+domId+'/services/edit/openvpn' },
	]

	return (
		<Tpl title="Servizi">
			<Search>
				<SearchText name="search" label="Cerca servizio" onChange={setSearchTxt} />
			</Search>
			<List>
				{ services && services.map(_id => <Service key={_id} _id={_id} domain={domId} handleListRefresh={handleRefresh} /> )}
			</List>
			<SpeedDial actions={actions} />
		</Tpl>
	)
}
export default ServiceListPage