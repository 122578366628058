import React from 'react';
import { useParams } from "react-router-dom";

import { TplWSidebar as Tpl } from '#Template'
import Form, { Input } from '#Form'

const GroupEditPage = ({ domainName }) => {
	const { domId, gid:_id } = useParams()

	const formDef = {
		callSet: _id ? "groups/update" : "groups/create",
		callGet: _id && "groups/get",
		idRedirect: _id => [ domId, 'groups/edit', _id ],
		_id,
		extraData: { domain:domId }
	}

	return (
		<Tpl title={ (_id ? 'Modifica' : 'Crea') + ' gruppo'}>
			<Form {...formDef}>
				<Input label="Nome gruppo" name="name" required disabled={Boolean(_id)} suffix={'@'+domainName} />
				<Input label="Descrizione" name="displayName" />
			</Form>
		</Tpl>
	)
}
export default GroupEditPage