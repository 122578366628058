import React, { useState } from 'react';
import { useParams } from "react-router-dom";

import { TplWSidebar as Tpl } from '#Template'
import Form, { Input, Select, Checkbox } from '#Form'
import hostingModes from './hostingModes.json'

const SUBTYPE_REDIRECT = 'redirect'

const ServiceEditHostingPage = ({ domainName }) => {
	const { domId:domain, id:_id } = useParams()
	const [ data, setData ] = useState()

	const formDef = {
		callSet: _id ? 'services/hosting/update' : 'services/hosting/create',
		callGet: _id && 'services/get',
		idRedirect: _id => [ domain, 'services/edit/hosting', _id ],
		_id,
		extraData: { domain },
		data,
		setData,
	}

	const title = (_id ? 'Modifica' : 'Crea') + ' servizio hosting'
	return (
		<Tpl title={title} backTo={'/' + domain + '/services'}>
			<Form {...formDef}>
				<Input label="Indirizzo" name="address" suffix={'.'+domainName} />
				<Select name="subtype" label="Motore" options={hostingModes} required emptyLabel="-- Selezionare un motore --" />
				<Input name="options.redirectAddress" label="Indirizzo di destinazione" required disabled={data?.subtype!==SUBTYPE_REDIRECT} />
				<Checkbox name="options.isPrivate" label="Modalità privata" />
			</Form>
		</Tpl>
	)
}
export default ServiceEditHostingPage
