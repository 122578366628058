import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { faUser } from '@fortawesome/pro-regular-svg-icons'

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'

import { Block, Checkbox, EntityAclsV2 } from '../genericAcl'

const User = ({ user, group, domId }) => {
	const api = useContext(APICtx)
	const [ isMember, setIsMember ] = useState(null)

	const handleChange = () => {
		setIsMember(null)
		api.call(isMember ? 'acl/membership/unset' : 'acl/membership/set', { user, group, domId })
			.then(handleRefresh)
	}
	const handleRefresh = () => api.call('acl/membership/get', { user, group }).then(setIsMember)
	useEffect(() => {
		handleRefresh()
	}, [ user ])

	return (
		<Checkbox
			label={user}
			handleChange={handleChange}
			active={isMember}
		/>
	)
}

const Users = ({ domId, group }) => {
	const api = useContext(APICtx)
	const [ users, setUsers ] = useState([])

	useEffect(() => {
		api.call('users/list', { domId }).then(setUsers)
	}, [ domId ])

	return (
		<Block label="Utenti" icon={faUser}>
			{ users.map(user => (
				<User key={user} user={user} group={group} domId={domId} />
			))}
		</Block>
	)
}

const GroupAclPage = () => {
	const api = useContext(APICtx)
	const { domId, gid:group } = useParams()
	const [ acls, setAcls ] = useState([])

	const handleSet = ({ aclTag, service }) =>
		api.call('acl/group/set', { group, tag:aclTag, service, domain:domId })
	const handleUnset = ({ aclTag, service }) =>
		api.call('acl/group/unset', { group, tag:aclTag, service, domain:domId })

	useEffect(() => {
		api.call('acl/group/list/services', { _id:group }).then(setAcls)
	}, [ domId ])

	return (
		<Tpl title="Permessi del gruppo">
			<EntityAclsV2
				servicesAcls={acls}
				handleSet={handleSet}
				handleUnset={handleUnset}
			>
				<Users domId={domId} group={group} />
			</EntityAclsV2>
		</Tpl>
	)
}
export default GroupAclPage