import React, { useState, useEffect, useContext } from 'react'
import { Routes, Route, useParams } from "react-router-dom"

import APICtx, { useApi } from '#api'
import Screen, { TplWSidebar as Tpl, TplConfig } from '#Template'
import DomainListPage from './pages/DomainList'
import DomainConfigPage from './pages/DomainConfig'
import DomainAclPage from './pages/DomainAcl'
import UserEditPage from './pages/user/Edit'
import UserListPage from './pages/user/List'
import DeviceEditPage from './pages/device/Edit'
import DeviceListPage from './pages/device/List'
import DeviceAclPage from './pages/device/Acl'
import UserAclPage from './pages/user/Acl'
import GroupEditPage from './pages/group/Edit'
import GroupsListPage from './pages/group/List'
import GroupAclPage from './pages/group/Acl'
import ServicesListPage from './pages/service/List'
import ServicesEditNextcloudPage from './pages/service/EditNextcloud'
import ServicesEditHostingPage from './pages/service/EditHosting'
import ServicesEditMailPage from './pages/service/EditMail'
import ServicesEditAppPage from './pages/service/EditApp'
import ServicesEditOpenVPNPage from './pages/service/EditOpenVPN'
import ServicesAclPage from './pages/service/Acl'

import { faChartNetwork, faUser, faUserGroup, faGlobe, faSign, faTools, faTachometerAlt, faServer, faShieldKeyhole } from '@fortawesome/pro-regular-svg-icons';

const Router = () => (
	<Routes>
		<Route path="/create" element={<DomainConfigPage />} />
		<Route path="/:domId/*" element={<DomainRouter />} />
		<Route path="/" element={<DomainListPage />} />
	</Routes>
)

const DomainRouter = () => {
	const api = useContext(APICtx)
	const { domId:domain } = useParams()
	const [ domData, setDomData ] = useState({})

	const handleRefresh = () => api.call('domain/get', { _id:domain }).then(setDomData)
	useEffect(() => {
		handleRefresh()
	}, [ domain ])

	const menu = [
		[{ label:"Elenco domini", icon:faChartNetwork, linkTo:'/' }],
		[
			// { label:"Dasboard", icon:faTachometerAlt, linkTo:'/'+domain },
			// { label:"DNS", icon:faSign, linkTo:'/'+domain+'/dns' },
			{ label:"Utenti", icon:faUser, linkTo:'/'+domain+'/users' },
			{ label:"Gruppi", icon:faUserGroup, linkTo:'/'+domain+'/groups' },
			{ label:"Servizi", icon:faGlobe, linkTo:'/'+domain+'/services' },
			{ label:"Dispositivi", icon:faServer, linkTo:'/'+domain+'/device/list' },
		],
	]
	if(api.chkAuth('admin'))
		menu.push([
			{ label:"Gestione dominio", icon:faShieldKeyhole, linkTo:'/'+domain+'/acl' },
			{ label:"Configura dominio", icon:faTools, linkTo:'/'+domain+'/config' },
		])

	const params = {
		domainName: domData?.name,
	}
	return (
		<TplConfig menu={menu} titlePrefix={domData?.name}>
			<Routes>
				<Route path="users/acl/:uid" element={<UserAclPage {...params} />} />
				<Route path="users/edit/:uid" element={<UserEditPage {...params} />} />
				<Route path="users/edit" element={<UserEditPage {...params} />} />
				<Route path="users" element={<UserListPage {...params} />} />

				<Route path="device/acl/:_id" element={<DeviceAclPage {...params} />} />
				<Route path="device/edit/:_id" element={<DeviceEditPage {...params} />} />
				<Route path="device/edit" element={<DeviceEditPage {...params} />} />
				<Route path="device/list" element={<DeviceListPage {...params} />} />

				<Route path="groups/acl/:gid" element={<GroupAclPage {...params} />} />
				<Route path="groups/edit/:gid" element={<GroupEditPage {...params} />} />
				<Route path="groups/edit" element={<GroupEditPage {...params} />} />
				<Route path="groups" element={<GroupsListPage {...params} />} />

				<Route path="services/acl/:svcId" element={<ServicesAclPage {...params} />} />
				<Route path="services/edit/nextcloud/:id" element={<ServicesEditNextcloudPage {...params} />} />
				<Route path="services/edit/nextcloud" element={<ServicesEditNextcloudPage {...params} />} />
				<Route path="services/edit/hosting/:id" element={<ServicesEditHostingPage {...params} />} />
				<Route path="services/edit/hosting" element={<ServicesEditHostingPage {...params} />} />
				<Route path="services/edit/mail/:id" element={<ServicesEditMailPage {...params} />} />
				<Route path="services/edit/mail" element={<ServicesEditMailPage {...params} />} />
				<Route path="services/edit/app/:id" element={<ServicesEditAppPage {...params} />} />
				<Route path="services/edit/app" element={<ServicesEditAppPage {...params} />} />
				<Route path="services/edit/openvpn/:id" element={<ServicesEditOpenVPNPage {...params} />} />
				<Route path="services/edit/openvpn" element={<ServicesEditOpenVPNPage {...params} />} />
				<Route path="services" element={<ServicesListPage {...params} />} />

				<Route path="acl" element={<DomainAclPage {...params} />} />
				<Route path="config" element={<DomainConfigPage {...params} />} />
			</Routes>
		</TplConfig>
	)
}

export default function App() {
	const api = useApi()

	const menu = [
		{ label:"Elenco domini", icon:faChartNetwork, linkTo:'/' }
	]

	return (
		<APICtx.Provider value={api}>
			<Screen menu={menu} toolAuth>
				{ api.chkAuth() ? <Router /> : <Tpl title="Sys Manager" /> }
			</Screen>
		</APICtx.Provider>
	)
}
