import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from '@mui/material/Box'

import APICtx from '#api'
import { TplWSidebar as Tpl, useTheme } from '#Template'
import Search, { SearchText } from '#Search'
import Cards, { Card } from '#Cards'
import Fab from '#Fab'

import { faPlus, faUser, faUserGroup, faGlobe, faServer, faShieldKeyhole, faGear } from '@fortawesome/pro-regular-svg-icons'
import { faFlask, faQuestion, faBriefcase, faHome, faWrench } from '@fortawesome/pro-regular-svg-icons'
import { faSign, faUsers, faWindowRestore, faCloud, faEnvelope } from '@fortawesome/pro-regular-svg-icons'

const zoneAvatars = {
	zgrid: faHome,
	dlab: faFlask,
	sht: faBriefcase,
	test: faWrench,
}

const DomainFlag = ({ value, icon, forceShow, isBool }) => {
	const theme = useTheme()
	const color = (isBool && !value) ? theme.getColor('error') : theme.getColor('success')

	if(!forceShow && !value)
		return null
	else
		return (
			<Box sx={{ display:'inline', mr:1, color }}>
				{ !isBool && value }
				<FontAwesomeIcon icon={icon} />
			</Box>
		)
}

const DomainFlags = ({ data }) => {
	return (
		<>
			<DomainFlag value={data.dnsCount} icon={faSign} forceShow isBool />
			<DomainFlag value={data.userCount} icon={faUser} forceShow />
			<DomainFlag value={data.groupCount} icon={faUsers} forceShow />
			<DomainFlag value={data.appCount} icon={faWindowRestore} />
			<DomainFlag value={data.hostingCount} icon={faGlobe} />
			<DomainFlag value={data.nextcloudCount} icon={faCloud} isBool />
			<DomainFlag value={data.emailCount} icon={faEnvelope} isBool />
			<DomainFlag value={data.deviceCount} icon={faServer} forceShow isBool />
		</>
	)
}

const DomainItem = ({ id }) => {
	const api = useContext(APICtx)
	const [ data, setData ] = useState({})

	const handleRefresh = () => api.call('domain/get-full', { _id:id }).then(setData)
	useEffect(() => { handleRefresh() }, [ id ])

	const actions = [
		{ label:'Utenti', icon:faUser, linkTo:'/'+id+'/users' },
		{ label:'Gruppi', icon:faUserGroup, linkTo:'/'+id+'/groups' },
		{ label:'Servizi', icon:faGlobe, linkTo:'/'+id+'/services' },
		{ label:'Dispositivi', icon:faServer, linkTo:'/'+id+'/device/list' },
	]
	if(api.chkAuth('admin'))
		actions.push(
			{ label:'Accesso', icon:faShieldKeyhole, linkTo:'/'+id+'/acl' },
			{ label:'Configura', icon:faGear, linkTo:'/'+id+'/config' },
		)
	return (
		<Card
			avatar={zoneAvatars[data.zone] || faQuestion}
			title={data.name}
			subtitle={data.zoneLabel}
			actions={actions}
		>
			<DomainFlags data={data} />
		</Card>
	)
}

const DomainList = ({ ids }) => {
	return (
		<Cards>
			{ ids && ids.map(id => <DomainItem key={id} id={id} /> )}
		</Cards>
	)
}

const DomainListPage = () => {
	const api = useContext(APICtx)
	const [ domains, setDomains ] = useState([])
	const [ searchTxt, setSearchTxt ] = useState('')

	const handleRefresh = () => { api.call('domain/list', { searchTxt }).then(setDomains) }
	useEffect(handleRefresh, [ searchTxt ])

	return (
		<Tpl title="Elenco domini">
			<Search>
				<SearchText name="test" label="Cerca dominio" onChange={setSearchTxt} />
			</Search>
			<DomainList ids={domains} />
			<Fab label="Crea dominio" icon={faPlus} linkTo="/create" />
		</Tpl>
	)
}
export default DomainListPage