import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import { EntityAclsV2 } from '../genericAcl'

const DeviceAclPage = () => {
	const api = useContext(APICtx)
	const { domId:domain, _id } = useParams()
	const [ acls, setAcls ] = useState([])

	const handleSet = ({ aclTag, service }) =>
		api.call('acl/device/set', { 
			device: _id,
			tag: aclTag,
			service,
			domain,
		})
	const handleUnset = ({ aclTag, service }) =>
		api.call('acl/device/unset', {
			device: _id,
			tag: aclTag,
			service,
			domain,
		})

	useEffect(() => {
		api.call('acl/device/list/services', { _id }).then(setAcls)
	}, [ domain ])

	return (
		<Tpl title="Permessi device">
			<EntityAclsV2
				servicesAcls={acls}
				handleSet={handleSet}
				handleUnset={handleUnset}
			/>
		</Tpl>
	)
}
export default DeviceAclPage