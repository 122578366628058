import React, { useState, useEffect, useContext, useMemo } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Masonry from '@mui/lab/Masonry';
import CheckboxMui from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';

import { faCloud, faDiagramProject, faEnvelope, faGlobe, faWindow } from '@fortawesome/pro-regular-svg-icons'

import APICtx from '#api'
import { Tooltip, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const servicesDef = {
	app: {
		label: 'App',
		icon: faWindow,
	},
	nextcloud: {
		label: 'Cloud',
		icon: faCloud,
		aclsUser: {
			user: 'Abilitato',
		},
		aclsGroup: {
			group: 'Abilitato',
		},
	},
	mail: {
		label: 'Email',
		icon: faEnvelope,
		aclsUser: {
			smtp: 'Invio',
			mailbox: 'Casella',
		},
		aclsGroup: {
			redirect: 'Forward',
		},
	},
	hosting: {
		label: 'Hosting',
		icon: faGlobe,
		aclsUser: {
			ftp: 'Accesso FTP',
			privateAllowed: 'Autenticazione HTTP',
		},
	},
	openvpn: {
		label: 'OpenVPN',
		icon: faDiagramProject,
		aclsUser: {
			client: 'Client VPN',
		},
	},
}

export const useListFlag = ({ entityTag, service, activeAcl }) => useMemo(() => {
	const aclField = entityTag==='user' ? 'aclsUser' : 'aclsGroup'

	const listActive = activeAcl || []
	const listDef = (service?.type && servicesDef[service.type]?.[aclField] && Object.keys(servicesDef[service.type][aclField])) || []
	const listService = service?.options?.[aclField] || []
	return [...new Set([ ...listActive, ...listDef, ...listService ])].sort()
}, [ entityTag, service, activeAcl ])

export const List = ({ children }) => {
	return (
		<Masonry columns={{ xs: 3, sm: 4 }} spacing={2} sx={{ m:0 }}>
			{children}
		</Masonry>
	)
}

export const Block = ({ icon, label, address, children }) => {
	return (
		<Paper sx={{ p:1 }}>
			<Typography variant="h5">
			{ icon && (
				<Tooltip title={label} sx={{ mr:2 }}>
					<FontAwesomeIcon icon={icon} />
				</Tooltip>
			)} {address || label}
			</Typography>
			<Box sx={{ mt:1 }}>
				{children}
			</Box>
		</Paper>
	)
}

export const Checkbox = ({ active, label, handleChange }) => {
	return (
		<FormControl fullWidth component="fieldset">
			{ active===null ? (
				<Box sx={{ p:1 }}>
					<Typography>
						<CircularProgress size={16} /> {label}
					</Typography>
				</Box>
			) : (
				<FormControlLabel
					checked={Boolean(active)}
					control={<CheckboxMui />}
					label={label}
					onChange={handleChange}
				/>
			)}
		</FormControl>
	)
}

const EntityServiceV2 = ({ serviceAcl, handleSet, handleUnset }) => {
	const serviceType = serviceAcl?.serviceType
	const def = (serviceType && servicesDef[serviceType]) || {}
	const serviceLabel = serviceAcl.serviceLabel

	return (
		<Paper sx={{ p:1 }}>
			<Typography variant="h5">
				{ def.icon && (
					<Tooltip title={def.label} sx={{ mr:2 }}>
						<FontAwesomeIcon icon={def.icon} />
					</Tooltip>
				)} {serviceLabel || def.label}
			</Typography>
			<Box sx={{ mt:1 }}>
				{ Boolean(serviceAcl?.acls?.length) && serviceAcl.acls.map(acl => (
					<AclSwitchV2
						key={acl.tag}
						aclTag={acl.tag}
						aclLabel={acl.label}
						isGranted={acl.isGranted}
						handleSet={handleSet}
						handleUnset={handleUnset}
					/>
				))}
			</Box>
		</Paper>
	)
}

const AclSwitchV2 = ({ aclTag, aclLabel, isGranted:isGrantedStartup, handleSet, handleUnset }) => {
	const [ isSyncing, setSincyng ] = useState(false)
	const [ isGranted, setGranted ] = useState(false)

	useEffect(() => {
		setGranted(isGrantedStartup)
	}, [])

	const handleSwitch = async () => {
		setSincyng(true)
		const op = isGranted ? handleUnset({ aclTag }) : handleSet({ aclTag })
		return op
			.then(() => {
				setSincyng(false)
				setGranted(!isGranted)
			})
			.catch(error => {
				setSincyng(false)
				throw error
			})
	}
	return (
		<Checkbox
			active={isSyncing ? null : isGranted}
			handleChange={handleSwitch}
			label={aclLabel}
		/>
	)
}

export const EntityAclsV2 = ({ servicesAcls, handleSet, handleUnset, children }) => (
	<Masonry columns={{ xs: 3, sm: 4 }} spacing={2} sx={{ m:0 }}>
		{children}
		{ servicesAcls.map(serviceAcl => (
			<EntityServiceV2
				key={serviceAcl.serviceID}
				serviceAcl={serviceAcl}
				handleSet={({ aclTag }) => handleSet({ aclTag, service:serviceAcl.serviceID })}
				handleUnset={({ aclTag }) => handleUnset({ aclTag, service:serviceAcl.serviceID })}
			/>
		))}
	</Masonry>
)

export const Service = ({ serviceId, user, group, device, domain }) => {
	const api = useContext(APICtx)
	const [ service, setService ] = useState({})
	const [ activeAcl, setActiveAcl ] = useState(null)

	const entityTag = user ? 'user' : 'group'

	const handleRefresh = () => api.call('acl/'+entityTag+'/get', { service:serviceId, user, group, domain }).then(setActiveAcl)
	const handleChange = (acl) => {
		const active = activeAcl.includes(acl)
		setActiveAcl(null)
		api.call(active ? 'acl/'+entityTag+'/unset' : 'acl/'+entityTag+'/set', { user, group, tag:acl, service:serviceId, domain })
			.then(handleRefresh)
	}
	useEffect(() => {
		api.call('services/get', { _id:serviceId, domain }).then(setService)
		handleRefresh()
	}, [ serviceId ])

	const def = (service?.type && servicesDef[service.type]) || {}
	const fullAcl = useListFlag({ entityTag, service, activeAcl })
	const aclLabels = (entityTag==='user' ? def?.aclsUser : def?.aclsGroup) || {}

	// const title = def.label + (service?.address ? ': '+service.address+'.'+service.domain : '')
	const address = service?.address ? service.address+'.'+service.domain : null
	return (
		<Block icon={def.icon} label={def.label} address={address}>
			{ fullAcl.map(tag => (
				<Checkbox
					key={tag}
					label={aclLabels[tag] || tag}
					handleChange={() => handleChange(tag)}
					active={Array.isArray(activeAcl) ? activeAcl?.includes(tag) : null}
				/>
			))}
		</Block>
	)
}
