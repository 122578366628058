import React, { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import { Grid, Paper, Typography } from '@mui/material';
import Masonry from '@mui/lab/Masonry';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Form, { Input, InputMulti, Password, InputDate, Checkbox } from '#Form'

const BlockTitle = ({ children }) =>
	<Typography variant="h5" gutterBottom sx={{ mb:2 }}>{children}</Typography>

const UserForm = ({ domainName }) => {
	const { domId, uid:_id } = useParams()
	
	const formDef = {
		callSet: _id ? 'users/update' : 'users/create',
		callGet: _id && "users/get",
		idRedirect: _id => [ domId, 'users/edit', _id ],
		_id,
		extraData: { domain:domId },
	}
	
	const passwordLabel = _id ? 'Reimposta password (vuoto per non cambiare)' : 'Password'
	return (
			<Form {...formDef}>
				<BlockTitle>Profilo utente</BlockTitle>
				
				<Input label="Nome utente" name="name" required disabled={Boolean(_id)} suffix={'@'+domainName} />
				<Input label="Nome mostrato" name="displayName" />
				<Checkbox label="Nome modificabile dall'utente?" name="acls.changeDisplayName" />
				<Password label={passwordLabel} name="password" randomLength="8" />
				<Checkbox label="Password modificabile dall'utente?" name="acls.changePassword" />
				<Input label="Email" name="email" />
			</Form>
	)
}

const SvcMail = () => {
	const { uid:_id } = useParams()
	
	const formDef = {
		callSet: [ "users/config/mail/set" ],
		callGet: _id && [ "users/config/mail/get" ],
		_id,
	}
	
	return (
			<Form {...formDef}>
				<BlockTitle>Impostazioni casella Email</BlockTitle>
				
				<Typography variant="subtitle2">NB: deleghe e inoltri funzionano solo per indirizzi attivi presenti su questa piattaforma</Typography>
				<InputMulti label="Delega" name="delegates" />
				<Checkbox label="Deleghe modificabili dall'utente?" name="acls.changeMailDelegates" />
				<InputMulti label="Inoltro" name="forwards" />
				<Checkbox label="Inoltri modificabili dall'utente?" name="acls.changeMailForwards" />
				
				<Typography variant="h6" sx={{ m:1 }}>Autorisponditore email</Typography>
				<InputDate label="Dal" name="vacation.fromDate" />
				<InputDate label="Al" name="vacation.toDate" />
				<Input label="Oggetto" name="vacation.subject" />
				<Input label="Testo" name="vacation.body" multiline />
				<Checkbox label="Attivo" name="vacation.isEnabled" />
				<Checkbox label="Autorisponditore modificabile dall'utente?" name="acls.changeMailVacation" />
			</Form>
	)
}

const SvcOpenvpn = ({ tag, address }) => {
	const { uid:_id } = useParams()
	
	const formDef = {
		callSet: [ "users/config/openvpn/set" ],
		callGet: _id && [ "users/config/openvpn/get" ],
		extraData: { service:tag },
		_id,
	}

	return (
			<Form {...formDef}>
				<BlockTitle>OpenVPN - {address}</BlockTitle>
				
				<Input label="IP statico" name="ipAddress" />
				<InputMulti label="Rotte locali" name="localRoutes" />
			</Form>
	)
}

const UserEditPage = ({ domainName }) => {
	const api = useContext(APICtx)
	const { uid:_id } = useParams()
	const [ userServices, setUserServices ] = useState([])

	useEffect(() => {
		if(_id)
			api.call('users/listServices', { _id }).then(setUserServices)
	}, [ _id ])

	const title = (_id ? 'Modifica' : 'Crea') + ' utente'
	return (
		<Tpl title={title}>
			<Masonry columns={{ xs: 1, lg: 2 }} spacing={2} sx={{ m:0 }}>
				<Paper>
					<UserForm domainName={domainName} />
				</Paper>
				{ userServices?.map(svc =>
					<Paper>
						{ (svc.type==='mail') && <SvcMail key={svc.tag} domainName={domainName} {...svc} /> }
						{ (svc.type==='openvpn') && <SvcOpenvpn key={svc.tag} domainName={domainName} {...svc} /> }
					</Paper>
				)}
			</Masonry>
		</Tpl>
	)
}
export default UserEditPage
