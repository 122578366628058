import React from 'react';
// import { useParams } from "react-router-dom";

// import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
// import Form, { Input } from '#Form'

const UserListPage = () => {
	return (
		<Tpl title="Accessi di gestione">
			WIP
		</Tpl>
	)
}
export default UserListPage