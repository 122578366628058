import React, { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import { Paper, Typography } from '@mui/material';
import Masonry from '@mui/lab/Masonry';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Form, { Input, InputMulti, Select } from '#Form'

import types from './types.json'
import osList from './osList.json'

const BlockTitle = ({ children }) =>
	<Typography variant="h5" gutterBottom sx={{ mb:2 }}>{children}</Typography>

const ItemForm = () => {
	const api = useContext(APICtx)
	const { domId:domain, _id } = useParams()

	const formDef = {
		callSet: _id ? 'device/update' : 'device/create',
		callGet: _id && "device/get",
		_id,
		extraData: { domain },
	}

	const isAdmin = api.auth.chk('admin')

	return (
		<Form {...formDef}>
			<BlockTitle>Profilo dispositivo</BlockTitle>
			
			<Input label="Nome" name="name" required disabled={!isAdmin} />
			<Input label="Descrizione" name="description" />
			<Select label="Tipologia" name="type" options={types} emptyLabel="--- seleziona una tipologia ---" required disabled={!isAdmin} />
			<Select label="OS" name="os" options={osList} emptyLabel="--- seleziona una sistema operativo ---" disabled={!isAdmin} />
			<InputMulti label="Management resources" name="mgmtResources" disabled={!isAdmin} />
		</Form>
	)
}

const SvcOpenvpn = ({ serviceID, serviceLabel, ...oth }) => {
	const { _id, domId } = useParams()

	const formDef = {
		callSet: "device/config/openvpn/set",
		callGet: _id && "device/config/openvpn/get",
		extraData: { _id, service:serviceID },
		_id,
	}

	return (
			<Form {...formDef}>
				<BlockTitle>OpenVPN - {serviceLabel}</BlockTitle>
				
				<Input label="IP statico" name="ipAddress" />
				<InputMulti label="Rotte locali" name="localRoutes" />
			</Form>
	)
}

const DeviceEditPage = () => {
	const api = useContext(APICtx)
	const { _id } = useParams()
	const [ userServices, setUserServices ] = useState([])

	useEffect(() => {
		_id && api.call('acl/device/list/services', { _id }).then(setUserServices)
	}, [ _id ])

	const title = (_id ? 'Modifica' : 'Crea') + ' dispositivo'
	return (
		<Tpl title={title} backTo="../device/list">
			<Masonry columns={{ xs: 1, lg: 2 }} spacing={2} sx={{ m:0 }}>
				<Paper>
					<ItemForm />
				</Paper>
				{ userServices?.map(svc =>
					<Paper>
						{ (svc.serviceType==='openvpn') && <SvcOpenvpn key={svc.serviceID} {...svc} /> }
					</Paper>
				)}
			</Masonry>
		</Tpl>
	)
}
export default DeviceEditPage
