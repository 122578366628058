import React, { useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box"

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Form, { Input, Select } from '#Form'
import Button from '#Button'
import { faTrash } from '@fortawesome/pro-solid-svg-icons';

const zoneOpts = [
	{ value:'dlab', label:'dLab' },
	{ value:'zgrid', label:'zGrid' },
	{ value:'sht', label:'SHT' },
	{ value:'test', label:'Test' },
]

const DomainConfigPage = () => {
	const api = useContext(APICtx)
	const navigate = useNavigate()
	const { domId } = useParams()

	const formDef = {
		callSet: domId ? 'domain/update' : 'domain/create',
		callGet: domId && "domain/get",
		_id: domId,
		idRedirect: _id => [ _id, 'config' ],
	}
	const handleDelete = () => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler elimnare il dominio?',
			onConfirm: () => api.call('domain/del', { _id:domId }).then(() => navigate('/')),
		})
	}

	return (
		<Tpl title="Impostazioni">
			<Form {...formDef}>
				<Input label="Dominio" name="name" required disabled={Boolean(domId)} />
				<Select label="Zona" name="zone" required emptyLabel="-- seleziona l'area di competenza --" options={zoneOpts} />
			</Form>
			{ domId && api.auth.chk('admin') && (
				<Box display="flex" justifyContent="right" sx={{ m:1 }}>
					<Button icon={faTrash} label="Elimina" color="error" onClick={handleDelete} />
				</Box>
			)}
		</Tpl>
	)
}
export default DomainConfigPage