import React from 'react';
import { useParams } from "react-router-dom";

import { TplWSidebar as Tpl } from '#Template'
import Form, { Input, InputMulti, InputNumber } from '#Form'

const ServiceEditOpenVPNPage = ({ domainName }) => {
	const { domId:domain, id:_id } = useParams()

	const formDef = {
		callSet: _id ? 'services/openvpn/update' : 'services/openvpn/create',
		callGet: _id && 'services/get',
		idRedirect: _id => [ domain, 'services/edit/openvpn', _id ],
		_id,
		extraData: { domain },
	}

	const title = (_id ? 'Modifica' : 'Crea') + ' servizio OpenVPN'
	return (
		<Tpl title={title} backTo={'/' + domain + '/services'}>
			<Form {...formDef}>
				<Input label="Indirizzo" name="address" suffix={'.'+domainName} required />
				<Input label="Descrizione" name="description" />
				<Input label="Porta" name="options.port" />
				<Input label="Subnet" name="options.netSubnet" required />
				<Input label="DHCP pool: da" name="options.netPoolFrom" required />
				<Input label="DHCP pool: a" name="options.netPoolTo" required />
				<InputMulti label="DNS" name="options.netDNS" />
				<Input label="Domain" name="options.netDomain" />
			</Form>
		</Tpl>
	)
}
export default ServiceEditOpenVPNPage
