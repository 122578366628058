import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { faPlus, faEdit, faTrash, faServer, faRouter, faKey } from '@fortawesome/pro-regular-svg-icons'
import { faDebian, faLinux } from '@fortawesome/free-brands-svg-icons'

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Search, { SearchText } from '#Search'
// import List, { ListItem } from '#List'
import Fab from '#Fab'
import DataList from '#DataList'
import { Avatar } from '#Display'
// import { Typography } from '@mui/material';

const icons = {
	debian11: faDebian,
	debian12: faDebian,
	openwrt: faLinux,
	ubiquitiER: faRouter,
	default: faServer,
}

const tabDef = [
	{ content:row => (
		<>
			<Avatar>{row?.os && icons.hasOwnProperty(row?.os) ? icons[row.os] : icons.default}</Avatar>
		</>
	)},
	{ label:"Nome", field:'name' },
	{ label:"Descrizione", field:'description' },
]

const DeviceListPage = () => {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])
	const [ searchTxt, setSearchTxt ] = useState('')
	const { domId:domain } = useParams()

	const handleDelete = ({ _id, name }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler elimnare il dispositivo "' + name + '"?',
			onConfirm: () => api.call('device/del', { _id }).then(handleRefresh),
		})
	}
	const handleRefresh = () => api.call('device/list', { domain, searchTxt }).then(setIds)
	useEffect(() => {
		handleRefresh()
	}, [ domain, searchTxt ])

	const defBtns = [
		{
			icon: faEdit,
			label: 'Modifica',
			linkTo: row => "../device/edit/" + row._id,
		},
		{
			icon: faKey,
			label: 'Permessi',
			linkTo: row => "../device/acl/" + row._id,
		},
	]
	if(api.auth.chk('admin'))
		defBtns.push({
			icon: faTrash,
			label: 'Elimina',
			onClick: handleDelete,
		})

	const getRow = _id => api.call('device/get', { _id })
	return (
		<Tpl title="Dispositivi">
			<Search>
				<SearchText name="search" label="Cerca dispositivo" onChange={setSearchTxt} />
			</Search>
			<DataList def={tabDef} ids={ids} getRow={getRow} rowActions={defBtns} pageSize={50} />
			<Fab label="Crea dispositivo" icon={faPlus} linkTo="../device/edit" />
		</Tpl>
	)
}
export default DeviceListPage