import React, { useState, useContext, useEffect } from 'react';
import { useParams } from "react-router-dom";

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Form, { Select, Checkbox } from '#Form'

import mailSenders from './mailSenders.json'

const calcOpts = enabledSenders => {
	const evalSenders = Object.entries(enabledSenders)
		.map(([ key, value ]) => value && key)
		.filter(Boolean)
	return [{ value:'', label:'-- Invio non attivo --' }]
		.concat(
			mailSenders.combos
				.filter(({ value }) => value.every(sender => evalSenders.includes(sender)))
				.map(({ value, label }) => ({ value:value.sort().join('+'), label }))
		)
}

// const calcEnabledSenders = data => {
// 	const def = data?.params?.mtaSenderEnabled || {}
// 	return Object.entries(def)
// 		.map(([ key, value ]) => value && key)
// 		.filter(Boolean)
// }

const ServiceEditMailPage = ({ domainName }) => {
	const api = useContext(APICtx)
	const { domId:domain, id:_id } = useParams()
	const [ data, setData ] = useState({})
	const [ senderOpts, setSenderOpts ] = useState([])

	useEffect(() => {
		setSenderOpts(calcOpts(data?.availableSenders || {}))
	}, [ data ])

	const formDef = {
		callSet: _id ? 'services/mail/update' : 'services/mail/create',
		callGet: _id && 'services/get',
		_id,
		idRedirect: _id => [ domain, 'services/edit/mail', _id ],
		extraData: { domain },
		data, setData,
		onLoad: data => {
			console.log('DD', data)
			const availableSenders = data?.options?.availableSenders?.length ? data.options.availableSenders : []
			setData({
				...data,
				mtaSender: data.subtype,
				availableSenders: Object.fromEntries(availableSenders.map(sender => [sender, true])),
			})
		}
	}

	const title = (_id ? 'Modifica' : 'Crea') + ' servizio mail'
	return (
		<Tpl title={title} backTo={'/' + domain + '/services'}>
			<Form {...formDef}>
				<Select name="mtaSender" label="Gateway SMTP in uscita" options={senderOpts} isRequired />
				{ api.auth.chk('admin') && 
					mailSenders.options.map(mailSender => (
						<Checkbox
							key={mailSender.key}
							name={"availableSenders."+mailSender.key}
							label={mailSender.label}
							// onChange={value => handleChEnabledSenders(mailSender.key, value)}
						/>
					))
				}
			</Form>
		</Tpl>
	)
}
export default ServiceEditMailPage
