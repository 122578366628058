import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { faPlus, faEdit, faKey, faLock, faUnlock, faTrash } from '@fortawesome/pro-regular-svg-icons'

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Search, { SearchText } from '#Search'
import List, { ListItem } from '#List'
import Fab from '#Fab'

const User = ({ _id, handleListRefresh }) => {
	const api = useContext(APICtx)
	const [ data, setData ] = useState({})

	const handleDelete = () => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler elimnare l\'utente "'+data?.address+'"?',
			onConfirm: () => api.call('users/del', { _id }).then(handleListRefresh),
		})
	}

	const handleRefresh = () => api.call('users/get', { _id }).then(setData)
	useEffect(() => {
		handleRefresh()
	}, [ _id ])
	
	const handleLockToggle = () =>
		api.call(data.isLocked ? 'users/unlock' : 'users/lock', { _id })
			.then(handleRefresh)

	const actions = [
		{ tooltip:'Modifica', icon:faEdit, linkTo:"edit/" + _id },
		{ tooltip:'Permessi', icon:faKey, linkTo:"acl/" + _id },
		{ tooltip:'Blocca', icon:faUnlock, onClick:handleLockToggle },
		{ tooltip:'Sblocca', icon:faLock, onClick:handleLockToggle },
		{ tooltip:'Elimina', icon:faTrash, onClick:handleDelete },
	]
	actions.splice(data.isLocked ? 2 : 3, 1)

	return (
		<ListItem
			avatar={data.isLocked ? faLock : data.address}
			label={data.address}
			sublabel={data.displayName}
			actions={actions}
		/>
	)
}

const UserListPage = () => {
	const api = useContext(APICtx)
	const [ users, setUsers ] = useState([])
	const [ searchTxt, setSearchTxt ] = useState('')
	const { domId } = useParams()

	const handleRefresh = () => api.call('users/list', { domId, searchTxt }).then(setUsers)
	useEffect(() => {
		handleRefresh()
	}, [ domId, searchTxt ])

	return (
		<Tpl title="Utenti">
			<Search>
				<SearchText name="search" label="Cerca utente" onChange={setSearchTxt} />
			</Search>
			<List>
				{ users && users.map(_id => <User key={_id} _id={_id} handleListRefresh={handleRefresh} /> )}
			</List>
			<Fab label="Crea utente" icon={faPlus} linkTo="edit" />
		</Tpl>
	)
}
export default UserListPage